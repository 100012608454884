import './dropdown'
import './keywordsfield'
import './selection'

addEventListener('DOMContentLoaded', (event) => {
	let textfields = document.querySelectorAll('ui-textfield')

	for (const textfield of textfields) {
		const input = textfield.querySelector('input')
		if (input) {
			input.addEventListener('focus', () => textfield.classList.add('focus'))
			input.addEventListener('blur', () => textfield.classList.remove('focus'))
		}
	}
})